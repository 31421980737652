@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  background-color: #efefef;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f3f3f3;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #a3a3a3;
  }
}
